import { headerFunc } from "./common/_header.js";
import { commonFunc } from "./common/_index.js";
import { homeFunc } from "./pages/_home.js";
import { swiperFunc } from "./lib/_swiper.js";

/**
 * メインルーチン
 */
window.addEventListener( 'load', function () {
  commonFunc();
  headerFunc();
  homeFunc();
  swiperFunc();
} );