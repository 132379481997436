export function swiperFunc() {
	const swiperObverse = new Swiper('.swiper--obverse', {
		loop: true,
		slidesPerView: 'auto',
		speed: 6000,
		autoplay: {
			delay: 0,
			stopOnLastSlide: false,
			disableOnInteraction: false,
			pauseOnMouseEnter: false,
			reverseDirection: false,
		},
	});

	const swiperReverse = new Swiper('.swiper--reverse', {
		loop: true,
		slidesPerView: 'auto',
		speed: 6000,
		autoplay: {
			delay: 0,
			stopOnLastSlide: false,
			disableOnInteraction: false,
			pauseOnMouseEnter: false,
			reverseDirection: false,
			reverseDirection: true,
		},
	});
}