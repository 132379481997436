/**
 * TOPページで使用する関数
 * @returns { void }
 */
export function homeFunc() {
	showMainvisual();
	showFixed();

	// ツアー動画のモーダル
	const lightboxVideo = GLightbox({
		selector: '.glightbox'
	});
}

function showMainvisual() {
	const showClassSuffix = '--show';

	// ロゴ表示
	const classNameLogo = 'mainvisual__logo';
	document.querySelectorAll('.' + classNameLogo).forEach(element => element.classList.add(classNameLogo + showClassSuffix));

	// 背景表示
	setTimeout(() => {
	  const className = 'mainvisual__background';
	  document.querySelectorAll('.' + className).forEach(element => element.classList.add(className + showClassSuffix));
	}, 1000);

	// テキスト表示
	setTimeout(() => {
	  const className = 'mainvisual__text';
	  document.querySelectorAll('.' + className).forEach(element => element.classList.add(className + showClassSuffix));
	}, 2000);

	setTimeout(() => {
	  // ヘッダー表示
	  const classNameHeader = 'l-header';
	  document.querySelectorAll('.' + classNameHeader).forEach(element => element.classList.add(classNameHeader + showClassSuffix));

	  // 申込表示
	  const classNameApplication = 'l-application--pc';
	  document.querySelectorAll('.' + classNameApplication).forEach(element => element.classList.add(classNameApplication + showClassSuffix));

	  // スクロール
	  const classNameScroll = 'mainvisual__scroll';
	  document.querySelectorAll('.' + classNameScroll).forEach(element => element.classList.add(classNameScroll + showClassSuffix));
	}, 3500);
  }


function showFixed() {
	$(window).on('scroll', function() {
		const showClassSuffix = '--show';
		const offset = 200;

		const classNameApplication = 'l-application--sp';
		$('.' + classNameApplication).each(function(){
			const scroll = $(window).scrollTop();

			// フェードイン
			if (scroll > offset) {
				$(this).addClass(classNameApplication + showClassSuffix);
			}
			// フェードアウト
			if (scroll <= offset) {
				$(this).removeClass(classNameApplication + showClassSuffix);
			}
		});

		const classNameToTop = 'l-to-top';
		$('.' + classNameToTop).each(function(){
			const scroll = $(window).scrollTop();

			// フェードイン
			if (scroll > offset) {
				$(this).addClass(classNameToTop + showClassSuffix);
			}
			// フェードアウト
			if (scroll <= offset) {
				$(this).removeClass(classNameToTop + showClassSuffix);
			}
		});
	});
}