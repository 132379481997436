/**
 * 全ページで使用する関数を呼び出す
 * @returns { void }
 */
export const commonFunc = () => {
	/**
	 * フェードイン処理
	 */
	$(window).on('scroll', function() {
		$('.u-fade').each(function(){
			const winHeight = $(window).height();
			const elemHeight = $(this).height();
			const position = $(this).offset().top;
			const scroll = $(window).scrollTop();
			const REMOVE_OFFSET = -200;
			// フェードイン
			if (scroll + winHeight > position){
				$(this).addClass('u-fade--in');
			}
			// フェードアウト
			if (scroll - elemHeight > position - REMOVE_OFFSET || scroll + winHeight < position + REMOVE_OFFSET){
				$(this).removeClass('u-fade--in');
			}
		});
	});

	$(function() {
		$('.u-fade').each(function(){
			const winHeight = $(window).height();
			const position = $(this).offset().top;
			const scroll = $(window).scrollTop();
			// フェードイン
			if (scroll + winHeight > position){
				$(this).addClass('u-fade--in');
			}
		});
	});
}
