export function headerFunc() {
	/**
	 * ハンバーガーメニュー
	 */
	$(document).on('click', '.hamburger', function() {
		$('.hamburger').toggleClass('close-nav');
		$('.l-flex-nav').toggleClass('open-nav');
	});

	$('.l-flex-nav-list__item a').on('click', function() {
	  $('.hamburger').removeClass('close-nav');
	  $('.l-flex-nav').removeClass('open-nav');
	});

	// ヘッダーの内容をSP用メニューにコピーする
	const headerInner = document.querySelector('.js-header');
	if (headerInner) {
		const clone = headerInner.cloneNode(true); // `true`は子要素も含めてクローンすることを意味します

		// `.l-flex-header`を選択し、クローンした要素を挿入
		const flexHeader = document.querySelector('.l-flex-header');
		if (flexHeader) {
			flexHeader.appendChild(clone);
		}
	}

	/**
	 * ページ内アンカーリンク位置調整
	 */
	$('a').on('click', function() {
	  let href = $(this).attr('href');

	  if(href.indexOf('#') != -1){
		let anchor = href.split("#");
		let target = $('#' + anchor[anchor.length - 1]);
		let position = Math.floor(target.offset().top);
		$("html, body").animate({scrollTop:position}, 550, "swing");
		return false;
	  }
	});
  }
